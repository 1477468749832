/* Make it more fun for students */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.game-title, .page-title {
  font-size: 2rem;
  color: #2a9d8f;
  display: inline-flex;
  align-items: center;
}

.navigation-button {
  background-color: gainsboro;
}

.masked-word {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  background-color: #ffeb3b;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
  transition: all 0.5s ease;
}

.masked-word.correct {
  background-color: #28a745;
  color: white;
}

.highlight {
  color: #e63946;
  font-weight: bold;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

button {
  font-size: 1rem;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
}

button:hover {
  transform: scale(1.05);
}

.choice-button.selected {
  background-color: #28a745;
  transform: translateY(-10px);
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

button.rounded {
  border-radius: 50px;
}

.speak-button {
  background-color: #ffb703;
  margin-left: 10px;
}

.submit-button {
  background-color: #e76f51;
}

/* Add styles for the edit button and word list items */

.word-list-name {
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.word-lists {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.word-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.word-list-button {
  font-size: 1rem;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #264653;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
}

.word-list-button:hover {
  transform: scale(1.05);
}

.edit-word-list-button {
  font-size: 0.8rem;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #f4a261;
  color: white;
  cursor: pointer;
}

.edit-word-list-button:hover {
  background-color: #e76f51;
}



.feedback {
  font-size: 1.2rem;
  margin-top: 20px;
  color: #264653;
}

.word-input {
  width: 100%;
  height: 100px;
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.start-button {
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
}

.start-button:hover {
  transform: scale(1.05);
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  25% {
      transform: translateX(-5px);
  }
  50% {
      transform: translateX(5px);
  }
  75% {
      transform: translateX(-5px);
  }
}

.masked-word.shake {
  animation: shake 0.5s;
}

.masked-word.incorrect {
  background-color: purple;
}

/* Styles for sharing and importing links */

.share-link-input {
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.share-word-list-button {
  font-size: 0.8rem;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #f4a261;
  color: white;
  cursor: pointer;
}

.share-word-list-button:hover {
  background-color: #e76f51;
}

@media (max-width: 600px) {
  .game-title, .page-title {
      font-size: 1.8rem;
  }
  .masked-word {
      font-size: 1.6rem;
  }
  button {
      font-size: 1.2rem;
      padding: 8px 16px;
  }
}

@media (max-width: 400px) {
  .masked-word {
      font-size: 1.2rem;
      padding: 5px 10px;
  }
  button {
      font-size: 0.8rem;
  }
}
